<template> 
  <div class="row" v-loading="loading">
    <div class="col">
      <el-tooltip
        class="item" effect="dark" v-if="!is_note_editing"  content="Click here to edit notes."
        placement="top">
        <div class="notes-display" @click="onEdit">
          {{notes}}
        </div>
      </el-tooltip>
      <div class="notes-edit" v-if="is_note_editing">
        <textarea
          v-model="edited_notes"
          @blur="onNoteEdited"
          rows="4"
          class="form-control"
          ref="edititem"
          placeholder="Notes"></textarea>
      </div>
    </div>
  </div> 
</template>
<script>

import { Tooltip } from "element-ui";
export default {
  props:{
    venue_id: {
      required: true,
    },
  },
  data() {
    return {
      is_note_editing: false,
      edited_notes: '',
      loading: true,
    };
  },
  components: {
    [Tooltip.name]: Tooltip,
  },
  async created() {
    await this.$store.dispatch("venues/getVenueNotes", { venue_id: this.venue_id });
    this.loading = false;
  },
  computed: {
    notes() {
      return this.$store.getters["venues/getVenueNote"](this.venue_id);;
    },
  },
  methods: {
    async onNoteEdited() {
      this.loading = true;
      await this.$store.dispatch("venues/updateVenueNotes", { venue_id: this.venue_id, notes: this.edited_notes});
      this.loading = false;
      this.is_note_editing = false;
    },
    onEdit() {
      this.is_note_editing=true;
      this.$nextTick(() => {
        this.$refs.edititem.focus();
      });
    },
  },
  watch: {
    notes() {
      this.edited_notes = this.notes;
    },
  },
};
</script>
<style scoped>
.notes-display {
    min-height: 30px;
    white-space: pre-wrap;
    border: 1px solid #fff;
}
.notes-display:hover {
    border: 1px solid #6cbcf7;
    border-radius: 5px;
}
</style>