<template>
	<div class="content">
		<div class="header d-flex align-items-center profile-header">
			<!-- Mask -->
			<span class="mask bg-gradient-info"></span>
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-lg-12">
						<router-link :to="{ name: 'LeadDetails', params: { id: leadId } }">
							<h1 class="display-3 text-white">
								{{ leadEvent.first_name }} {{ leadEvent.last_name }}
							</h1>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<base-header class="pb-6">
			<div class="row align-items-center py-4">
				<div class="col-lg-6 col-7">
					<nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
						<route-breadcrumb></route-breadcrumb>
					</nav>
				</div>
			</div>
		</base-header>

		<div class="container-fluid mt--6" v-loading="loading">
			<div class="row">
				<div class="col-xl-8">
					<template>
						<card>
							<div class="row">
								<div class="col-lg-6">
									<lead-information />
								</div>
								<div class="col-lg-6">
									<div class="form-group row">
										<label class="col-md-12 col-form-label form-control-label text-muted">Enquiries
											({{ enquiriesLength }})</label>
										<div class="col-md-12">
											<enquiries />
										</div>
									</div>
								</div>
							</div>
						</card>
					</template>
				</div>
				<div class="col-xl-4">


					<card class="">
						<div slot="header" class="row align-items-center">
							<div class="col-12">
								<h3 class="mb-0">Venues Recommendations</h3>
							</div>
						</div>
						<send-recommendation :recommendations.sync="recommendations" :lead-event-id="leadEventId" />
					</card>

				</div>
			</div>
		</div>

		<div class="container-fluid" v-loading="loading">
			<div class="row">
				<div class="col-xl-3">
					<template>
						<card>
							<div slot="header" class="row align-items-center">
								<div class="col-12">
									<h3 class="mb-0" id="lead_details_heading">
										Venue Requirements
									</h3>
								</div>
							</div>
							<venue-search @search="onVenueRequirements" @reset="onSearchReset" />
						</card>
					</template>
				</div>

				<div class="col-xl-3">
					<card>
						<div slot="header" class="row align-items-center">
							<div class="col-12">
								<h3 class="mb-0">Venues Matches</h3>
							</div>
						</div>
						<div class="venue_matches_results">
							<i v-if="!venueRequirementResults.length">
							{{
								searchResultsIndicator
							}}</i>
							<template v-else>
								<div v-for="venue in venueRequirementResults" :key="venue.id">
									<venue-list-view :venue="venue" @showDetails="selectVenueForDetails"></venue-list-view>
								</div>
							</template>
						</div>
					</card>

				</div>
				<div class="col-xl-6">
					<venue-details :venue="selectedVenue" :recommendations.sync="recommendations" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LeadInformation from "@/views/Widgets/Karen/LeadInformation.vue";
import Enquiries from "@/views/Widgets/Karen/Enquiries.vue";
import VenueSearch from "@/views/Widgets/Karen/VenueSearch.vue";
import VenueDetails from "@/views/Widgets/Karen/VenueDetails.vue";
import VenueListView from "@/views/Widgets/Karen/VenueListView.vue";
import SendRecommendation from "@/views/Widgets/Recommendations/SendRecommendation";


// custom components
export default {
	data() {
		return {
			loading: true,
			leadId: parseInt(this.$route.params.id),
			leadEventId: parseInt(this.$route.params.eventId),
			searchQuery: "",
			venueRequirementResults: [],
			selectedVenue: {},
			recommendations: [],
			searchResultsIndicator: "Search for venues",
		};
	},
	components: {
		Enquiries,
		LeadInformation,
		VenueSearch,
		VenueDetails,
		VenueListView,
		SendRecommendation,
	},
	computed: {
		leadEvent() {
			return this.$store.getters["leadEvent/getLeadEventsById"](this.leadEventId);
		},
		enquiriesLength() {
			return this.leadEvent?.totals?.enquiries;
		},
	},
	async mounted() { },
	async created() {
		await this.$store.dispatch("leadEvent/getSingle", this.leadEventId);
		this.loading = false;
	},
	methods: {
		onVenueRequirements(venues) {
			this.venueRequirementResults = venues;
			this.searchResultsIndicator = "No results";
		},
		selectVenueForDetails(venue) {
			this.selectedVenue = venue;
		},
		onSearchReset() {
			this.venueRequirementResults = [];
			this.searchResultsIndicator = "Search for venues";
		},
	},
	watch: {
		venueRequirementResults() {
			this.selectedVenue = {};
		},
	},
};
</script>

<style lang="scss">
.custom-search .form-group {
	margin-bottom: 0;
}

.custom-filters {
	a:hover {
		background: grey;
	}

	.card-header {
		padding: 0;
		border-bottom: 0;
	}

	.card {
		box-shadow: none;
	}

	.card-header:after {
		right: 0;
	}

	.card-body {
		padding: 1.5rem 0 0 0;
	}
}

.btn-remove {
	visibility: hidden;
	opacity: 0;
	transition: opacity, 0.8s;
}

li:hover .btn-remove {
	visibility: visible;
	opacity: 1;
	transition: opacity, 0.8s;
}

.avatar-xxl {
	width: 100%;
	height: auto;
}

.venue_match p {
	line-height: 1;
}

.venue_match .details p {
	margin-bottom: 5px;
}

.venue_match {
	padding-bottom: 30px;
}

.venue_matches_results {
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: scroll;
}
</style>
