<template>
  <div class="row align-items-center venue_match">
    <div class="col-12">
      <h4 class="mb-0"><a href="javascript:" @click="selectVenue">{{venue.name}}</a></h4>
      <p>
        <small>
          <i class="fa fa-map-marker-alt"></i> {{venue.address.street_name}}, {{venue.address.city}} {{venue.address.city}} {{venue.address.state_short}} {{venue.address.country}}
        </small>
      </p>
    </div>
    <div class="col-4">
      <img
        alt="Image placeholder"
        class="avatar avatar-xxl c-p"
        :src="venue.thumbnail"
        @click="selectVenue"
      />
    </div>
    <div class="col-8">
      
      <div class="row details">
        <div class="col-5">
          <p>
            <small><strong>Guests:</strong></small>
          </p>
        </div>
        <div class="col-7">
          <p><small>{{venue.general_capacity}}</small></p>
        </div>
      </div>
      <div class="row details">
        <div class="col-5">
          <p>
            <small><strong>Cost:</strong></small>
          </p>
        </div>
        <div class="col-7">
          <p><small>{{venue.fees.fee_from_value ? venue.fees.fee_from + ' ' + venue.fees.fee_from_value : ''}}</small></p>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    venue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    venue_style() {
      let length = this.venue.styles.length;
      return this.venue.styles[length - 1];
    },
    selectVenue() {
      this.$emit('showDetails', this.venue);
      window.scrollTo(0,1100);
    },
  },
};
</script>
<style scoped>
.venue_match hr {
  margin-top: 20px;
  margin-bottom: 0px;;
}
.c-p {
  cursor: pointer;
}
</style>