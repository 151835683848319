<template>
	<div id="venue_requirements" v-loading="loading">

		<div class="row">

			<div class="col-md-12">
				<hr class="hr-text" data-content="SELECT ONE:">

				<base-input class="custom-search input-group-lg input-group-flush mb-0"
					placeholder="Enter a venue name " id="search_input" type="search" v-model="form.search" />
			</div>

		</div>


		<collapse>
			<collapse-item name="1" id="1" class="m-b-0">
				<template v-slot:title>
					<h5 class="mb-0" id="tab-location">Location</h5>
				</template>
				<div>
					<div class="form-group mt-2 mb-2">
						<GmapAutocomplete id="mapAutoCompleteId" ref="mapAutoComplete" class="form-control"
							placeholder="Melbourne" v-on:place_changed="getAddressData" :options="{
								componentRestrictions: { 'country': ['au', 'uk'] }
							}" @change="locationChanged">
						</GmapAutocomplete>
					</div>
					<label class="col-md-12 col-form-label form-control-label">Radius</label>
					<div class="col-md-12 filter-item mb-4">
						<base-dropdown title-classes="btn btn-secondary" :title="radius" id="radius">
							<a class="dropdown-item" @click="radiusChanged('')">--</a>
							<a class="dropdown-item 5k" @click="radiusChanged('5')">5km</a>
							<a class="dropdown-item" @click="radiusChanged('10')">10km</a>
							<a class="dropdown-item" @click="radiusChanged('25')">25km</a>
							<a class="dropdown-item" @click="radiusChanged('50')">50km</a>
						</base-dropdown>
					</div>
				</div>
			</collapse-item>



			<collapse-item name="2" id="2" class="m-b-0">
				<template v-slot:title>
					<h5 class="mb-0" id="tab-region">Region</h5>
				</template>
				<div class="mt-2 mb-4">
					<base-dropdown title-classes="btn btn-secondary" :title="region" id="region">
						<a class="dropdown-item" @click="regionChanged('')">-</a>
						<template v-for="(item, index) in venueRegions">
							<a class="dropdown-item" @click="regionChanged(item)" :key="index">{{ item }}</a>
						</template>
					</base-dropdown>
				</div>

			</collapse-item>

			<hr class="hr-text" data-content="FILTERS">


			<collapse-item name="3" id="3" class="m-b-0">
				<template v-slot:title>
					<h5 class="mb-0" id="tab-style">Event / Style</h5>
				</template>
				<div class="pb-4">
					<label class="col-md-12 col-form-label form-control-label mt-4">Event Type</label>
					<base-dropdown title-classes="btn btn-secondary mt-2" :title="event_type" id="event_type">
						<template v-for="(item, index) in event_types">
							<a class="dropdown-item" @click="eventTypeChanged(item.value, item.label)" :key="index">{{
								item.label }}</a>
						</template>
					</base-dropdown>
					<label class="col-md-12 col-form-label form-control-label mt-2 mt-4">Style</label>
					<el-select multiple collapse-tags v-model="form.category" placeholder="Style" id="style">
						<el-option v-for="option in venueStyles" :value="option.value" :label="option.label"
							:key="option.label">
						</el-option>
					</el-select>
				</div>
			</collapse-item>

			<collapse-item name="4" id="4" class="m-b-0">
				<template v-slot:title>
					<h5 class="mb-0" id="tab-guests">Guests</h5>
				</template>
				<div>
					<div class="col-md-12 filter-item mb-3">
						<label class="col-md-12 col-form-label form-control-label mt-4">Event Configuration</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.event_configuration"
							id="event_configuration">
							<a class="dropdown-item yes" @click="eventConfigurationChanged('Dinner & Dance')">Dinner &
								Dance</a>
							<a class="dropdown-item" @click="eventConfigurationChanged('Standing')">Standing</a>
						</base-dropdown>
					</div>
					<div class="col-md-12 filter-item">
						<base-input placeholder="Enter number of guests" v-model="form.guests" id="guests"></base-input>
					</div>

				</div>
			</collapse-item>

			<collapse-item name="5" id="5" class="m-b-0">
				<template v-slot:title>
					<h5 class="mb-0" id="tab-pricing">Pricing</h5>
				</template>
				<div>

					<div class="col-md-12 filter-item">
						<base-input placeholder="Price per person (Max)" v-model="form.price_per_person_max"
							id="price_per_person_max">
							<template v-slot:addonLeft>$</template>
						</base-input>
					</div>

					<hr class="hr-text" data-content="or">

					<div class="col-md-12 filter-item">
						<base-input placeholder="Price per day (Max)" v-model="form.price_per_day_max"
							id="price_per_day_max">
							<template v-slot:addonLeft>$</template>
						</base-input>
					</div>

				</div>
			</collapse-item>

			<collapse-item name="6" id="6" class="m-b-0">
				<template v-slot:title>
					<h5 class="mb-0" id="tab-other">Other</h5>
				</template>
				<div class="pb-4 row">
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label mt-4">Accommodation</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.accommodation_available"
							id="accommodation_available">
							<a class="dropdown-item" @click="accommodationChanged('--')">--</a>
							<a class="dropdown-item yes" @click="accommodationChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="accommodationChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label mt-4">Parking</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.parking_available"
							id="parking_available">
							<a class="dropdown-item" @click="parkingChanged('--')">--</a>
							<a class="dropdown-item yes" @click="parkingChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="parkingChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-12">
						<hr class="hr-text" data-content="-">
					</div>
					<div class="col-md-6">
						<label class="col-md-12 col-form-label form-control-label">Venue hire fee</label>
						<base-input placeholder="Venue hire fee (Max)" v-model="form.venue_hire_maximum_price"
							id="venue_hire_maximum_price">
							<template v-slot:addonLeft>$</template>
						</base-input>
					</div>
					<div class="col-md-6">
						<label class="col-md-12 col-form-label form-control-label">Min spend</label>
						<base-input placeholder="Min spend" v-model="form.min_spend_max_at_peak"
							id="min_spend_max_at_peak">
							<template v-slot:addonLeft>$</template>
						</base-input>
					</div>
					<div class="col-12">
						<hr class="hr-text m-0" data-content="-">
					</div>
					<div class="col-md-6 late-licence">
						<label class="col-md-12 col-form-label form-control-label">Late license</label>
						<el-time-select v-model="form.late_license_end_time" :picker-options="{
							start: '00:00',
							step: '00:30',
							end: '23:59',
						}" size="mini" placeholder="Late license">
						</el-time-select>
					</div>
					<div class="col-md-6">
						<label class="col-md-12 col-form-label form-control-label">Mandap ceremony</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.mandap_ceremony"
							id="mandap_ceremony">
							<a class="dropdown-item" @click="mandapCeremonyChanged('--')">--</a>
							<a class="dropdown-item yes" @click="mandapCeremonyChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="mandapCeremonyChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-12">
						<hr class="hr-text m-0" data-content="-">
					</div>
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label">Ceremonies onsite</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.ceremonies_onsite"
							id="ceremonies_onsite">
							<a class="dropdown-item" @click="ceremoniesOnsiteChanged('--')">--</a>
							<a class="dropdown-item yes" @click="ceremoniesOnsiteChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="ceremoniesOnsiteChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label">Outdoor space</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.outdoor_space"
							id="outdoor_space">
							<a class="dropdown-item" @click="outdoorSpaceChanged('--')">--</a>
							<a class="dropdown-item yes" @click="outdoorSpaceChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="outdoorSpaceChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-12">
						<hr class="hr-text" data-content="-">
					</div>
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label">External catering permitted</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.external_catering_permitted"
							id="external_catering_permitted">
							<a class="dropdown-item" @click="externalCateringPermittedChanged('--')">--</a>
							<a class="dropdown-item yes" @click="externalCateringPermittedChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="externalCateringPermittedChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label">Accommodation onsite</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.accommodation_onsite"
							id="accommodation_onsite">
							<a class="dropdown-item" @click="accommodationOnsiteChanged('--')">--</a>
							<a class="dropdown-item yes" @click="accommodationOnsiteChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="accommodationOnsiteChanged('No')">No</a>
						</base-dropdown>
					</div>
					<div class="col-12">
						<hr class="hr-text" data-content="-">
					</div>
					<div class="col-6">
						<label class="col-md-12 col-form-label form-control-label">Ceremony only</label>
						<base-dropdown title-classes="btn btn-secondary mt-2" :title="form.ceremony_only"
							id="ceremony_only">
							<a class="dropdown-item" @click="ceremonyOnlyChanged('--')">--</a>
							<a class="dropdown-item yes" @click="ceremonyOnlyChanged('Yes')">Yes</a>
							<a class="dropdown-item" @click="ceremonyOnlyChanged('No')">No</a>
						</base-dropdown>
					</div>
				</div>
			</collapse-item>

		</collapse>
		<div class="row">
			<div class="col-lg-12 mt-5">
				<base-button type="primary" @click="searchVenues" id="search_btn">Search</base-button>
				<base-button type="secondary" @click="resetForm" id="reset_btn">Reset</base-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
import { Select, Option, TimeSelect } from "element-ui";

export default {
	data() {
		return {
			form: {
				category: '',
				accommodation_available: '--',
				parking_available: '--',
				event_configuration: 'Dinner & Dance',
				guests: '',
				location: '',
				location_lat: '',
				location_lng: '',
				price_per_person_min: '',
				price_per_person_max: '',
				price_per_day_min: '',
				price_per_day_max: '',
				radius: '',
				lead_id: parseInt(this.$route.params.id),
				search: '',
				event_type: 'wedding',
				regions: '',
				venue_hire_maximum_price: '',
				min_spend_max_at_peak: '',
				late_license_end_time: '',
				mandap_ceremony: '--',
				ceremonies_onsite: '--',
				outdoor_space: '--',
				external_catering_permitted: '--',
				accommodation_onsite: '--',
				ceremony_only: '--'
			},
			style: 'Select',
			leadId: parseInt(this.$route.params.id),
			radius: 'Select',
			region: 'Select',
			loading: true,
			location_search_autocomplete: '',
			event_type: 'Wedding',
			event_types: [
				{ label: 'Wedding', value: 'wedding' },
				{ label: 'Corporate', value: 'corporate' },
				{ label: 'Social', value: 'social' }
			]
		};
	},
	components: {
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Select.name]: Select,
		[Option.name]: Option,
		[TimeSelect.name]: TimeSelect
	},
	async created() {
		await this.$store.dispatch("venues/getStyles");
		await this.$store.dispatch("venues/getRegions");
		console.log('finished');
		this.loading = false;
	},
	methods: {
		eventTypeChanged(value, label) {
			this.form.event_type = value;
			this.event_type = label;
			this.form.category = '';
			this.style = 'Select';
		},
		styleChanged(value, label) {
			this.form.category = value;
			this.style = label;
		},
		regionChanged(value) {
			this.form.region = value;
			this.region = value;
			if (!value) {
				this.region = 'Select';
			}
		},
		accommodationChanged(value) {
			this.form.accommodation_available = value;
		},
		parkingChanged(value) {
			this.form.parking_available = value;
		},
		ceremoniesOnsiteChanged(value) {
			this.form.ceremonies_onsite = value;
		},
		outdoorSpaceChanged(value) {
			this.form.outdoor_space = value;
		},
		externalCateringPermittedChanged(value) {
			this.form.external_catering_permitted = value;
		},
		accommodationOnsiteChanged(value) {
			this.form.accommodation_onsite = value;
		},
		ceremonyOnlyChanged(value) {
			this.form.ceremony_only = value;
		},
		mandapCeremonyChanged(value) {
			this.form.mandap_ceremony = value;
		},
		radiusChanged(value) {
			if (value) {
				this.radius = value + 'km';
			} else {
				this.radius = 'Select';
			}

			this.form.radius = value;
		},
		eventConfigurationChanged(value) {
			this.form.event_configuration = value;
		},
		async searchVenues() {
			// console.log(this.$route.params.eventId);
			this.loading = true;
			let params = this.form;
			params.event_id = this.$route.params.eventId;
			console.log(params);
			let results = await this.$store.dispatch("venues/search", params);
			this.loading = false;
			this.$emit('search', results);
		},
		getAddressData(address) {
			let location = address.name;
			let lat = address.geometry.location.lat();
			let lng = address.geometry.location.lng();
			this.form.location = location;
			this.form.location_lat = lat;
			this.form.location_lng = lng;
		},
		resetForm() {
			this.form.category = '';
			this.form.accommodation_available = '--';
			this.form.parking_available = '--';
			this.form.event_configuration = 'Dinner & Dance';
			this.form.guests = '';
			this.form.location = '';
			this.form.location_lat = '';
			this.form.location_lng = '';
			this.form.price_per_person_min = '';
			this.form.price_per_person_max = '';
			this.form.price_per_day_min = '';
			this.form.price_per_day_max = '';
			this.form.radius = '';
			this.form.search = '';
			this.form.region = '';
			this.form.venue_hire_maximum_price = '';
			this.form.min_spend_max_at_peak = '';
			this.form.late_license_end_time = '';
			this.form.mandap_ceremony = '--';
			this.form.ceremonies_onsite = '--';
			this.form.outdoor_space = '--';
			this.form.external_catering_permitted = '--';
			this.form.accommodation_onsite = '--';
			this.form.ceremony_only = '--';


			this.$refs.mapAutoComplete.$el.value = '';
			this.style = 'Select';
			this.radius = 'Select';
			this.region = 'Select';
			this.$emit('reset');
		},
		locationChanged(e) {
			if (!e.target.value) {
				this.form.location = '';
				this.form.location_lat = '';
				this.form.location_lng = '';
			}
		}
	},
	computed: mapState({
		venueStyles(state) {
			let venueStyles = state.venues.venueStyles;
			let country = this.lead.country && this.lead.country.toLowerCase();
			if (venueStyles[country]) {
				let eventType = this.event_type;
				return venueStyles[country].filter(item => {
					if (item.event_type.toLowerCase() === eventType.toLowerCase()) {
						return true;
					}
					return false;
				});
			}
			return [];
		},
		venueRegions(state) {
			return state.venues.venueRegions;
		},
		lead() {
			return this.$store.getters["leads/getLeadById"](this.leadId);
		}
	})
};
</script>
<style lang="scss">
#venue_requirements .accordion>.card {
	overflow: visible;
}

.venue-style-list {
	.show.dropdown {
		.dropdown-menu.show {
			max-height: 200px;
			overflow-y: scroll;
		}
	}
}

.accordion .card {
	margin-bottom: 0;
	box-shadow: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.accordion>.card>.card-header {
	padding: 1rem 0;
}

.accordion .card .card-body {
	padding: 0;
}

.accordion .card .card-body .col-form-label {
	padding: 0;
	font-size: .8rem;
}

.accordion .card .card-body .filter-item {
	padding: 0;
}

.late-licence .el-date-editor.el-input,
.late-licence .el-date-editor.el-input__inner {
	width: 120px;
	line-height: 44px;
}

.late-licence .el-input--mini .el-input__inner {
	height: 44px;
	line-height: 44px;
}
</style>
