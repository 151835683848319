<template>
  <card v-if="venue.name">
    <div slot="header" class="row align-items-center">
      <div class="col-10">
        <h3 class="mb-0" id="manage_recommendation_heading">
          Venue Details
        </h3>
      </div>

    </div>

    <div class="row" >
      <div class="col-8">
        <h2 class="m-0">{{venue.name}}</h2>
        <p>
          <small><i class="fas fa-map-marker-alt"></i> {{venue.address.address}}</small
          >
        </p>
      </div>

      <div class="col-1">
        <a href="#!" class="avatar">
          <img
            alt="Image placeholder"
            :src="venue.venue_specialist_photo"
          />
        </a>
      </div>
      <div class="col-3">
        <p class="m-0">
          <small><strong>Venue Specialist:</strong></small>
        </p>
        <p><small>{{venue.venue_specialist_name}}</small></p>
      </div>
    </div>

    <hr/>

    <div class="row">
      <div class="col-12">
        <h6 slot="title" class="heading-small text-muted mb-0">
          Recent Updates
        </h6>
        <br />
      </div>   
    </div>
    <venue-note :venue_id="venue.id"></venue-note>

    <hr/>

    <div class="row">
      <div class="col-12">
        <h6 slot="title" class="heading-small text-muted mb-0">
          Venue Contact Info
        </h6>
        <br />
      </div>   
    </div>

    <div class="row">
      <div class="col">
        {{venue.inspection_contact_name}}<br/>
        {{venue.inspection_contact_email}}
      </div>
    </div>

    <hr/>

    <div class="row">
      <div class="col-12">
        <h6 slot="title" class="heading-small text-muted mb-0">
          Venue Details
        </h6>
        <br />
      </div>
              
    </div>

    <div class="row">
      <div class="col">
        <div v-if="!fullDescription">
          {{venue.description}}
        </div>
        <div v-else v-html="fullDescription" class="venue_description">
        </div>
      </div>
    </div>

    <hr/>

    <div class="row">
      <div class="col-12">
        <h6 slot="title" class="heading-small text-muted mb-0">
          Venue Packages
        </h6>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="venue_description" v-if="packages">
          <venue-packages :packages="packages" :country="venue.country"></venue-packages>
        </div>
      </div>
    </div>

    <hr/>
            
    <div class="row">
      <div class="col-12">
        <h6 slot="title" class="heading-small text-muted mb-0">
          Venue Gallery
        </h6>
        <br />
      </div>
              
    </div>

    <div class="row">
      <el-carousel
        :interval="5000" arrow="always" trigger="click" :autoplay="false"
        class="w-100 col-12">
        <el-carousel-item v-for="(imgs,index_first) in imageGallery()" :key="index_first">
          <div class="row">
            <div class="col-4"  v-for="(img,index) in imgs" :key="index" >
              <img
                class="img-fluid  rounded lazyloaded" :src="img"
                alt="" data-ll-status="loaded">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <hr/>
            
    <div class="row">
      <div class="col">
        <h6 slot="title" class="heading-small text-muted mb-0">
          Venue Spaces
        </h6>
      </div>


      <div class="col-auto recommendation_queue">
        <div class="col-3 text-right">
          <div
            v-if="isSelected(venue)"
            @click="removeFromQueue(venue)"
          >
            <badge
              rounded tag="a" class="btn-add remove"  href="javascript:"
              type="danger">
              Remove
            </badge>
          </div>
          <div v-else @click="addToQueue(venue)">
            <badge
              rounded tag="a" class="btn-add add" href="javascript:"
              :type="addToListLabelActive(venue) ? 'success' : 'secondary'">
              {{addToListLabelComputed(venue)}}
            </badge>
          </div>
        </div>
      </div>

      <div class="col-auto">
        <base-dropdown title-classes="btn btn-secondary" id="select_room_button">
          <template v-slot:title>
            {{selectedRoomName || 'Select A Space'}}
          </template>
          <a class="dropdown-item" href="javascript:"  @click="chooseRoom(allLabel)">{{allLabel}}</a>
          <template v-for="room in venue.rooms">
            <a class="dropdown-item" href="javascript:" :key="room.room_name" @click="chooseRoom(room)">{{room.room_name}}</a>
          </template>
        </base-dropdown>
      </div>
    </div>
    <br />

    <div class="row" v-if="selectedRoom.room_name">
      <div class="col-6">
        <span class="title d-block mb-3">{{selectedRoom.room_name}}</span>
        <small class="d-block"><i class="fa fa-drafting-compass"></i> {{selectedRoom.room_space_sqm ? selectedRoom.room_space_sqm + 'sqm' : ''}} / {{selectedRoom.room_space_sqft ? selectedRoom.room_space_sqft + 'sqft' : ''}}</small><br />
        <span class="description pb-3">
          <p>
            {{selectedRoom.room_description ? selectedRoom.room_description.default_description : ''}}
          </p>
        </span>
      </div>
      <div class="col-6">
        <div class="row pt-3">
          <div class="col-12 col-md-12 pb-3 fancybox-wrapper">
            <el-image 
              class="img-fluid w-100 relative fancybox"
              :src="roomImage()" 
              :preview-src-list="roomImageGallery()">
            </el-image>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row pt-3">
          <div class="col-6">
            <p class="small text-uppercase font-weight-bold">
              Guest Capacity
            </p>
            <template v-for="(capacity, index) in selectedRoom.room_guests">
              <small :key="index">
                {{capacity.type}}:
                <span>{{capacity.min_guests || 0}} - </span>

                {{capacity.max_guests}} </small
              ><br :key="index + 'br'"/>
            </template>
          </div>

          <div class="col-6 room-features">
            <p class="small text-uppercase font-weight-bold">
              Features
            </p>
            <template v-for="(feature, index) in selectedRoom.room_features">
              <span :key="index">
                <i class="fa fa-check"></i>&nbsp;<small>{{feature.label}}</small>
                <br :key="index + 'br'"/>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <center><i>Select a space for more details</i></center>
    </div>
  </card>
</template>
<script>
import VenueNote from "@/views/Widgets/Karen/VenueNote.vue";
import VenuePackages from "@/views/Widgets/Karen/VenuePackages.vue";
import { Carousel, CarouselItem, Image, Tooltip } from "element-ui";
export default {
  props: {
    venue: {
      type: Object,
      required: true,
    },
    recommendations: {
      type: Array,
    },
  },
  data() {
    return {
      selectedRoom: {},
      leadId: parseInt(this.$route.params.id),
      allLabel: '-- All --',
    };
  },
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Image.name]: Image,
    VenueNote,
    VenuePackages,
  },
  async mounted() {
    await this.$store.dispatch("leads/getSingle", {id: this.leadId});
  },
  async updated() {
    if (this.venue.id) {
      await this.$store.dispatch("venues/getVenueDescription", { venue_id: this.venue.id, event_type: this.venue.event_type });
      await this.$store.dispatch("venues/getVenuePackages", { venue_id: this.venue.id, event_type: this.venue.event_type });
    }
  },
  computed:{
    lead() {
      return this.$store.getters["leads/getLeadById"](this.leadId);
    },
    shouldSelectSpaces() {
      if (this.lead.country.toLowerCase() === 'au') {
        return true;
      }
      return false;
    },
    fullDescription() {
      return this.$store.getters["venues/getVenueDescription"](this.venue.id);;
    },
    packages() {
      return this.$store.getters["venues/getVenuePackages"](this.venue.id);;
    },
    selectedRoomName() {
      return this.selectedRoom ? (this.selectedRoom === this.allLabel ? this.allLabel : this.selectedRoom.room_name ) : '';
    },
  },
  methods: {
    addToListLabelComputed(venue) {
      if (!this.shouldSelectSpaces || this.isRoomSelected()) {
        return 'Add To Queue';  
      }
      return "Select Space";
    },
    addToListLabelActive(venue) {
      if (!this.shouldSelectSpaces || this.isRoomSelected()) {
        return true;  
      }
      return false;
    },
    imageGallery() {
      let gallery = Object.assign([], this.venue.image_gallery);
      let size = 3;
      gallery = Array.from({ length: Math.ceil(gallery.length / size) }, (v, i) =>
        gallery.slice(i * size, i * size + size)
      );
      return gallery;
    },
    chooseRoom(venue) {
      this.selectedRoom = venue;
    },
    roomImage() {
      return this.selectedRoom && this.selectedRoom.room_images ? this.selectedRoom.room_images[0].url : '';
    },
    roomImageGallery() {
      let images = [];
      this.selectedRoom.room_images.forEach(function(image) {
        images.push(image.url);
      });
      return images;
    },
    isSelected: function(venue) {
      let item = this.recommendations.find((item) => parseInt(item.id) === parseInt(venue.id));
      return !!item;
    },
    isRoomSelected() {
      if (this.selectedRoom === this.allLabel) {
        return true;
      }
      return this.selectedRoom && this.selectedRoom?.room_name ? true : false;
    },
    addToQueue(venue) {
      if (this.shouldSelectSpaces && !this.isRoomSelected()) {
        return false;
      }
      venue.space = this.selectedRoom && this.selectedRoom !== this.allLabel ? this.selectedRoom.room_name : '';
      this.recommendations.push(venue);
      this.$emit('update:recommendations' , this.recommendations);
      this.showRecommendationWidget();
    },
    removeFromQueue(venue) {
      var recommendations = this.recommendations.filter(function(item) {
        return parseInt(item.id) !== parseInt(venue.id);
      });
      this.$emit('update:recommendations' , recommendations);
      this.showRecommendationWidget();
    },
    showRecommendationWidget() {
      this.$nextTick(() => {
      // block: 'end' will scroll to top of element instead of bottom
        document.getElementById('send_recommendation_widget').scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      });
    },
  },
  watch: {
    venue() {
      this.selectedRoom = {};
    },
  },
};
</script>
<style lang="scss">
.venue_description {
  max-height: 400px;
  overflow-x: scroll;
  b, strong {
    font-weight: bold !important;
  }
}
.el-carousel__arrow {
  background-color: rgba(31,45,61,.71);
}
.el-carousel__arrow:hover {
  background-color: rgba(31,45,61,.85);
}
</style>