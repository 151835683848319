<template>
  <div>
    <form v-loading="loading" id="leadinformation" ref="leadInformation" :class="widthClass">
      <div class="row">
        <div class="col-lg-12">
          <div
            :name="enquiry.id"
            v-for="enquiry in enquiries"
            :key="enquiry.id"
            v-if="enquiry.enquiry_venue && enquiry.enquiry_venue.name"
          >
            <!-- {{enquiries}} -->
            <h3 slot="title" class="mb-1">
              {{enquiry.enquiry_venue.name}}  ({{enquiry.createdDate | moment("DD/MM/YYYY")}})
            </h3>
          </div>
        </div>
      </div>
    </form>
    <div>
      <button class="btn-primary btn-sm btn" @click="toggleShowMore" v-if="displayShowMore">{{isOnFullWidth ? 'Show Less...' : 'Show More...'}}</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      leadId: parseInt(this.$route.params.id),
      loading: false,
      heightInteracted: false,
      displayShowMore: false,
      isOnFullWidth: true,
    };
  },
  updated() {
    let height = this.$refs.leadInformation.clientHeight;
    if (height > 300 && !this.heightInteracted) {
      this.displayShowMore = true;
      this.isOnFullWidth = false;
    }
  },
  computed: {
    enquiries() {
      let lead = this.$store.getters["leads/getLeadById"](this.leadId);
      return lead.enquiries || [];
    },
    widthClass() {
      if (this.isOnFullWidth) {
        return 'fullWidth';
      } else {
        return 'partialWidth';
      }
    },
  },
  methods: {
    toggleShowMore() {
      this.heightInteracted = true;
      this.isOnFullWidth = !this.isOnFullWidth;
    },
  },
};
</script>
<style scoped>
#leadinformation {
  overflow: hidden;
}
.partialWidth {
  height: 280px;
}
.fullWidth {
  height: auto;
}
</style>