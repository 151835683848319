<template>
  <form v-loading="loading" id="leadinformation">
    <div class="row">
      <div class="col-lg-6">
        <div class="row justify-content-center pt-80">
          <div class="col-lg-3 order-lg-2">
            <div class="card-profile-image" style="height: 50px">
              <img
                src="/img/custom/user.png"
                class="rounded-circle"
              />
            </div>
          </div>
        </div>
        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div class="d-flex justify-content-between">
          
          </div>
        </div>
        <div class="card-body pt-0 pb-0">
          <div class="text-center">
            <h5 class="h3">{{ lead.first_name }} {{ lead.last_name }}</h5>
            <div class="h5 font-weight-300">
              {{ lead.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group row">
          <label class="col-md-12 col-form-label form-control-label text-muted">Phone</label>
          <div class="col-md-12">
            <strong>{{lead.phone}}</strong>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-12 col-form-label form-control-label  text-muted">Guest count</label>
          <div class="col-md-12">
            <strong>{{guestCount}}</strong>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-12 col-form-label form-control-label  text-muted">Wedding Date</label>
          <div class="col-md-12">
            <strong>{{weddingDate}}</strong>
          </div>   
        </div> 
      </div>
    </div>
  </form>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      leadId: parseInt(this.$route.params.id),
      loading: false,
    };
  },
  computed: {
    lead() {
      return this.$store.getters["leads/getLeadById"](this.leadId);
    },
    lastEnquiry() {
      if (!this.lead.enquiries) {
        return "";
      }
      let enquiry = this.lead.enquiries[this.lead.enquiries.length - 1];
      return enquiry;
    },
    weddingDate() {
      if (!this.lastEnquiry) {
        return "";
      }
      return moment(this.lastEnquiry.eventDate).format("DD/MM/YYYY");
    },
    guestCount() {
      if (!this.lastEnquiry) {
        return "";
      }
      return this.lastEnquiry.estimatedGuests;
    },
  },
};
</script>
