<template>
  <div>
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="packages"
    >
      <el-table-column label="Name" prop="package_name" min-width="270px" sortable>
      </el-table-column>
      <el-table-column label="Price" min-width="120px" prop="package_price">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            {{getPrice(row)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" min-width="50px" prop="package_inclusions">
        <template v-slot="{ row }">
          <span class="btn-action" @click="showInclusions(row.package_inclusions)">
            ...
          </span>
        </template>
      </el-table-column>
    </el-table>
    <modal
      :show.sync="modalInlusions"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <card
        type="secondary"
        shadowheader-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0"
      >
        <template>
          <div class="text-center mb-3">
            <h5 class="modal-title" id="exampleModalLabel">
              Package Inclusions
            </h5>
          </div>
        </template>
        <template>
          <div v-html="inclusions">
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui"; 

export default {
  props: {
    packages: {
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      modalInlusions: false,
      inclusions: '',
    };
  },
  methods: {
    getPrice(row) {
      let country = this.country.toLowerCase();
      let locale = country === 'au' ? 'en-AU' : (country === 'uk' ? 'en-GB' : '');
      let currency = country === 'au' ? 'AUD' : (country === 'uk' ? 'GBP' : '');
      let label = new Intl.NumberFormat(locale, { maximumSignificantDigits: 10, style: 'currency', currency: currency }).format(row.package_price);
      let perPerson = row.price_type_per_person[0] ? ' Per Person' : '';
      return label + perPerson;
    },
    showInclusions(inclusions) {
      this.modalInlusions = true;
      this.inclusions = inclusions;
    },
  },
};
</script>

<style scoped>
.btn-action {
  cursor: pointer;
  font-weight: bold;
}
</style>